img {
    margin-top: 20px;
    margin-bottom: 20px;
}

iframe, img {
  border: 1px solid $gray-02;
  border-radius: 5px;
}

.multiple-choice {
    margin-top: 20px;
    display: inline-block;
    .answer-key{
      display: flex;
      margin-top: 30px;
      color: $gray-05;
      .strongly-disagree {
        margin-left: auto;
      }
    }
    .radio {
        //display: inline;
        float: left;
        margin: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
        display: flex;
        cursor: pointer;
        user-select: none;
        .radio-outer {
            width: 16px;
            height: 16px;
            border: 3px solid $gray-03;
            border-radius: 14px;
            float: left;
            display: flex;
            justify-content: center;
            align-items: center; 
            margin-right: 7px;
            .radio-inner {
                width: 10px;
                height: 10px;
                background-color: $gray-03;
                border-radius: 5px;
            }
            &.disabled {
              border-color: $gray-09;
              color: $gray-03;
            }

        }
        .text {
            float: left;
            font-size: 16px;
            position: relative;
            top: 3px;
        }
    }
}

.check-answer {
    background-color: $button-bg;
    border-color: $button-green01;
    margin-top: 20px;
}

.select-answer-message {
    margin-top: 20px;
    font-style: italic;
}

.loading {
  margin-top: 40px;
  width: 272px;
  text-align: center;
}

.keyboard-container {
  position: relative;
  width: 800px;
  height: 430px;
  margin-bottom: 30px;
  margin-top: 30px;
  background-image: url("http://drift.nps.edu/population-stereotypes/images/5.jpg");
  background-repeat: no-repeat;
  background-size: 800px;
  .key {
    width: 45px;
    height: 45px;
    //background-color: rgba(92, 91, 91, .5);
   // border: 1px solid $gray-02;
    //box-shadow: 2px 2px 4px $gray-06;
   // border-radius: 3px;
    position: absolute;
    input {
      width: 35px;
      height: 35px;
      padding: 0px;
     // margin-top: 5px;
     // margin-left: 5px;
      font-size: 24px;
      text-align: center;
      border: 1px solid #178c9b;
      border-radius: 2px;
      background-color: transparent;
    }
  }
  .number0 {
    bottom: 55px;
    left: 98px;
  }
  .number1 {
    top: 264px;
    left: 142px;
  }
  .number2 {
    top: 198px;
    left: 185px;
  }
  .number3 {
    left: 229px;
    top: 264px;
  }
  .number4 {
    bottom: 55px;
    left: 274px;
  }
  .number5 {
    bottom: 55px;
    left: 493px;
  }
  .number6 {
    top: 265px;
    left: 538px;
  }
  .number7 {
    top: 198px;
    left: 583px;
  }
  .number8 {
    top: 264px;
    left: 626px;
  }
  .number9 {
    bottom: 55px;
    left: 669px;
  }
}

.error-message {
  color: $red;
  margin-top: 20px;
  font-weight: bold;
}

.window-container {
  position: relative;
  width: 650px;
  height: 506px;
  margin-bottom: 30px;
  margin-top: 30px;
  background-image: url("http://drift.nps.edu/population-stereotypes/images/10.jpg");
  background-repeat: no-repeat;
  background-size: 650px;
  .key {
    width: 100px;
    height: 100px;
    border: 1px solid $gray-02;
    box-shadow: 2px 2px 4px $gray-06;
    border-radius: 3px;
    display: inline-block;
    margin-right: 20px;
    position: relative;
    input {
      width: 88px;
      height: 86px;
      margin-top: 5px;
      margin-left: 5px;
      font-size: 28px;
      text-align: center;
      border: 1px solid $gray-06;
      border-radius: 2px;
      background-color: rgba(255, 255, 255, .5);
    }
    &.number0 {
      top: 63px;
      left: 44px;
    }
    &.number1 {
      top: 61px;
      left: 144px;
    }
    &.number2 {
      top: 59px;
      left: 248px;
    }
  }
}

.calculator-container {
  border: 1px solid $gray-02;
  border-radius: 5px;
  padding: 15px;
  width: 340px;
  height: 490px;
  margin-bottom: 20px;
  position: relative;
  background-image: url("http://drift.nps.edu/population-stereotypes/images/14.jpg");
  background-repeat: no-repeat;
  background-size: 370px;
  .absolute {
    position: absolute;
  }
  .button {
    line-height: 50px;
    display: inline-block;
    input {
      width: 46px;
      height: 43px;
      padding-top: 2px;
      border-radius: 2px;
      border: 1px solid $gray-02;
      text-align: center;
      font-size: 30px;
      background-color: rgba(255, 255, 255, .5);
    }
  }
  .number0 {
    right: 59px;
    top: 159px;
  }
  .number1 {
    right: 59px;
    top: 247px;
  }
  .number2 {
    right: 59px;
    top: 334px;
  }
  .number3 {
    right: 59px;
    top: 421px;
  }
  .number4 {
    right: 129px;
    top: 421px;
  }
}

.elevator-container {
  width: 400px;
  height: 690px;
  border: 1px solid $gray-02;
  border-radius: 5px;
  margin-bottom: 20px;
  background-image: url("http://drift.nps.edu/population-stereotypes/images/15.jpg");
  background-repeat: no-repeat;
  background-size: 400px;
  .light-container {
    width: 170px;
    margin: 0 auto;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 20px;
    .light {
      width: 60px;
      height: 60px;
      display: inline-block;
      position: relative;
      input {
        width: 55px;
        height: 55px;
        border-radius: 30px;
        margin-left: 5px;
        margin-top: 4px;
        border: 1px solid $gray-02;
        text-align: center;
        font-size: 30px;
        background-color: rgba(255, 255, 255, .5);
      }
      &.number0 {
        top: 46px;
        left: -20px;
      }
      &.number1 {
        top: 45px;
        left: 43px;
      }
      &.number2 {
        top: 110px;
        left: -19px;
      }
      &.number3 {
        top: 109px;
        left: 42px;
      }
      &.number4 {
        top: 171px;
        left: -19px;
      }
      &.number5 {
        top: 170px;
        left: 41px;
      }
      &.number6 {
        top: 229px;
        left: -18px;
      }
      &.number7 {
        top: 230px;
        left: 40px;
      }
      &.number8 {
        top: 284px;
        left: -17px;
      }
      &.number9 {
        top: 286px;
        left: 40px;
      }
    }
  }
}
