#instructor-view, #student-view {
  .tests {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    overflow: auto;
    li {
      background-color: $gray-10;
      margin-right: 20px;
      float: left;
      height: 195px;
      width: 195px;
      padding: 17px;
      margin-bottom: 15px;
      border-radius: 5px;
      cursor: pointer;
      border: 2px solid $gray-04;
      box-shadow: 3px 3px 5px $gray-06;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .enabled, .disabled {
        font-weight: bold;
      }
      .enabled {
        color: $button-green01;
      }
      .disabled {
        color: $bright-red;
      }
      &:hover {
        background-color: white;
      }
      h3 {
        margin-top: 0px;
      }
      p {
        margin: 0px;
        max-height: 65px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
      }
    }
  }
  .overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    background-color: rgba(0, 0, 0, .2);
  }
  .imports-window {
    background-color: white;
    position: fixed;
    flex-direction: column;
    display: flex;
    padding: 20px;
    border-radius: 5px;
    max-width: 900px;
    width: calc(100% - 80px);
    max-height: calc(100% - 100px);
    .title {
      max-width: 30%;
    }
    .description {
      max-width: 70%;
    }
    .title, .description {
      overflow: hidden;
      text-overflow: ellipsis;
    }
    button {
      float: right;
      margin-top: 8px;
    }
    .shareable-tests-container {
      height: 100%;
      max-height: 500px;
      overflow-y: scroll;

      .shareable-tests {
        margin: 5px;
        li {
          display: flex;
          background-color: $gray-10;
          padding: 7px;
          border-radius: 3px;
          cursor: pointer;
          border: 2px solid transparent;
          margin-bottom: 12px;
          &:hover {
            border: 2px solid $gray-05;
          }
          .title {
            font-weight: bold;
            margin-right: 10px;
          }
          .description {
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            p {
              margin: 0;
              margin-top: -3px;
            }
          }
        }
      }
    }
    
  }

}

#test-settings {
  input, textarea {
    margin-top: 5px;
  }
  input[type=checkbox] {
    margin-left: 5px;
    margin-top: 0px;
  }
}

.popup {
  position: fixed;
  background-color: white;
  width: 400px;
  border-radius: 3px;
  padding: 20px;
  height: 100px;
  left: calc(50% - 200px);
  top: calc(50% - 50px);
  p {
    margin: 0px;
  }
  button {
    margin-right: 20px;
  }
  box-shadow: 5px 5px 8px #BBBBBB;
}