#page {
  max-width: 1200px;
  margin: 0 auto;
  margin-bottom: 20px;
}

#content {
  max-width: 1230px;
  .nav {
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      overflow: hidden;
      display: inline;
      //background-color: #333;
    }

    li {
      display: flex;
      cursor: pointer;
    }

    li a {
      color: $base-text-color;
      text-align: center;
      padding: 14px 16px;
      text-decoration: none;
    }

    /* Change the link color to #111 (black) on hover */
    li a:hover {
      background-color: $gray-07;
    }

    .active {
      background-color: $gray-08;
    }
  }

}

button {
  background-color: $blue-02;
  color: white;
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  padding: 7px 12px 7px 12px;
  cursor: pointer;
  border: 2px solid $blue;
  border-radius: 3px;
  user-select: none;
  i {
    color: white;
  }
  &.green {
    background-color: $button-bg;
    border-color: $button-green01;
    border-radius: 3px;
    margin-top: 20px;
  }
  &.red {
    background-color: $button-red02;
    border-color: $button-red02;
  }
  &:hover {
    background-color: transparent;
    color: $gray-01;
    i {
       color: $blue;
    }
  }
  &.disabled {
    background-color: $gray-09;
    color: $gray-04;
    border-color: $gray-09;
    cursor: default;
    i {
      color: $gray-04;
    }
  }
  &.submit {
    border: 2px solid $button-green02;
    i {
      color: $button-green01;
    }
  }
  &.danger {
    background-color: $button-red02;
    border-color: $button-red02;
    &:hover {
      color: white;
      background-color: $button-red01;
      border-color: $button-red01;
    }
  }
  &.green {
    background-color: $button-green02;
    border-color: $button-green02;
    &:hover {
      color: white;
      background-color: $button-green01;
      border-color: $button-green01;
    }
  }
 
}

p {
  margin-top: 20px;
  margin-bottom: 20px;
}

.callout {
  background-color: $gray-10;
  padding: 20px;
  margin-top: 30px;
  border-radius: 3px;
  line-height: 22px;
}

.success-message {
  font-size: 18px;
  color: $button-green02;
  font-weight: bold;
  display: block;
}

.warning-text {
  font-size: 18px;
  font-weight: bold;
  color: $red;
  display: block;
}

.hidden {
  display: none;
}

.question-count {
  font-weight: bold;
  margin-bottom: 30px;
  margin-top: 15px;
}

.navigation {
  width: 100%;
  display: inline-block;
  margin-top: 20px;
  .prev-next {
    font-weight: 600;
    font-size: 14px;
    line-height: 28px;
    i {
      cursor: pointer;
    }
    .fa-arrow-left {
      margin-right: 5px;
    }
    .fa-arrow-right {
      margin-left: 5px;
    }
  }
}

.error {
  color: $red;
  font-weight: bold;
}

.dialog {
  color: $red;
  font-weight: bold;
  .buttons {
    .danger {
      margin-right: 15px;
    }
  }
}
