body {
  .sortable-item, .item-list-editor li {
    border: 1px solid $gray-04;
    margin-bottom: 20px;
    padding: 15px 5px 15px 15px;
    border-radius: 3px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 2px 2px 5px $gray-08;
    cursor: move;
    background-color: white;
    input {
      width: 80%;
    }
    button {
      margin-right: 10px;
      background-color: $gray-08;
      border-color: $gray-08;
      color: $gray-01;
      &:hover {
        background-color: white;
        border-color: $gray-01;
      }
    }
    .edit {
      background-color: $gray-08;
    }
    .question-text, .category-name {
      max-width: 720px;
      line-height: 20px;
    }
    .options {
      display: flex;
      align-items: center;
      .category {
        margin-right: 10px;
      }
      .reverse-selection {
        display: flex;
        input {
          margin-left: 5px;
          width: auto;
          position: relative;
          top: 1px;
          margin-right: 10px;
        }
      }
      select {
        min-width: 100px;
      }
    }
    
  }
  .sortable-item-dragging {
    box-shadow: 4px 4px 7px $gray-07;
  }
}

#admin {
  margin-bottom: 50px;
  .item-list-editor {
    margin-top: 20px;
    .disabled {
      background-color: $gray-10;
      color: $gray-07;
      border-color: $gray-09;
      cursor: default;
      pointer-events: none;
    }
    
  }
  .save {
    margin-right: 10px;
  }
  .data {
    margin-top: 5px;
    margin-left: 32px;
  }

  .error, .complete {
    margin-top: 20px;
  }

  .complete {
    color: $button-green02;
    font-weight: bold;
  }

  .dialog {
    .warning-text {
      margin-right: 20px;
    }
    .green {
      margin-left: 10px;
    }
  }

  .quiz-editor {
    margin-top: 50px;
  }

}
