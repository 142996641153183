#result {
  display: inline-flex;
  align-items: flex-end;
  justify-content: space-evenly;
  margin-bottom: 25px;
  .bar-container {
    position: relative;
    width: 130px;
    display: table;
    border-bottom: 2px solid $gray-04;
    .title, .your-choice {
      text-align: center;
    }
    .title {
      position: absolute;
      font-size: 14px;
      width: 100%;
      top: 100%;
      line-height: 22px;
      margin-top: 7px;
      .text {
        margin-left: 10px;
        margin-right: 10px;
        .your-choice {
          font-size: 13px;
          display: block;
          margin-top: 7px;
          line-height: 14px;
        }
      }
    }
    .table-title {
      table {
        margin: 0 auto;
      }
      tr:first-child {
        border-top: 1px solid $gray-02;
      }
      tr {
        border-bottom: 1px solid $gray-02;
        border-radius: 3px;
        td {
          border-left: 1px solid $gray-02;
          width: 25px;
        }
        td:last-child {
          border-right: 1px solid $gray-02;
        }
      }
    }
    .small {
      font-size: 18px;
    }
    .bar {
      background-color: $gray-05;
      width: 55px;
      position: relative;
      margin: 0 auto;
      transition: 1s;
      .count {
        font-size: 20px;
        text-align: center;
        position: absolute;
        color: $gray-02;
        width: 70px;
        left: -7px;
        top: -26px;
      }
  
      &.chosen {
        background-color: $button-bg;
      }
      
    }
  }
}

.result-container {
  margin-bottom: 35px;
}

.result-text {
  margin-top: 25px;
  padding-top: 20px;
  font-weight: 600;
  margin-bottom: 30px;
  .result-instruction {
    font-weight: 300;
    font-style: italic;
  }
}
